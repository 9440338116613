import React, {Component} from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class BlogDetails extends Component {
    render() {
        return (
            <div className="blog-site">
                {/* Header Navbar */}
                <Navbar/>

                {/* breadcrumb Area */}
                <div className="breadcrumb-area ptb--120 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h2 className="title">SORA - “Cơn sốt” mới trong ngành công nghiệp video.</h2>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Trang chủ</a></li>
                                        <li>-</li>
                                        <li>Chi tiết bài viết</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Blog Details */}
                <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-inner">
                                    <article className="single-post-details">
                                        <div className="content mt--50">
                                            <h2>SORA - “Cơn sốt” mới trong ngành công nghiệp video.</h2>
                                        </div>
                                        <div className="post-thumbnail">
                                            <img className="w-100"
                                                 src={require('../assets/images/blog/blog_2_1.jpg')}
                                                 alt="Blog Images"/>
                                        </div>
                                        <div className="content mt--50">
                                            <h3>Những điểm nổi bật của Sora:</h3>
                                            <h4 className="content ml--30">Tạo video từ văn bản: <span style={{ fontWeight: 'normal' }}>Sora biến ý tưởng trong đầu bạn thành video thực tế chỉ với vài dòng mô tả.</span></h4>
                                            <h4 className="content ml--30">Chất lượng hình ảnh cao: <span style={{ fontWeight: 'normal' }}>Video được tạo ra sở hữu độ phân giải cao, hình ảnh sắc nét và chuyển động mượt mà, 
                                            không thua kém video được quay bằng camera chuyên nghiệp.</span></h4>
                                            <h4 className="content ml--30">Đa dạng phong cách: <span style={{ fontWeight: 'normal' }}>Sora có thể tạo ra video với nhiều phong cách khác nhau, từ hiện thực đến hoạt hình, phù hợp với mọi nhu cầu sử dụng.</span></h4>
                                            <h4 className="content ml--30">Dễ dàng sử dụng: <span style={{ fontWeight: 'normal' }}>Giao diện đơn giản, dễ hiểu cho phép người dùng ở mọi trình độ tạo video mà không cần kiến thức chuyên môn về dựng phim.</span></h4>
                                        </div>
                                        <div className="content mt--50">
                                            <h3>Tiềm năng trong nhiều lĩnh vực:</h3>
                                            <h4 className="content ml--30">Sáng tạo nội dung video: <span style={{ fontWeight: 'normal' }}>Sora là một công cụ đắc lực cho các nhà sáng tạo nội dung, giúp họ tạo video nhanh chóng, hiệu quả và tiết kiệm chi phí.</span></h4>
                                            <h4 className="content ml--30">Quảng cáo và tiếp thị: <span style={{ fontWeight: 'normal' }}>Sora giúp doanh nghiệp tạo video quảng cáo thu hút, truyền tải thông điệp hiệu quả đến khách hàng tiềm năng.</span></h4>
                                            <h4 className="content ml--30">Giáo dục và đào tạo: <span style={{ fontWeight: 'normal' }}>Tạo ra video bài giảng sinh động, thu hút sự chú ý của học sinh, sinh viên.</span></h4>
                                            <h4 className="content ml--30">Giải trí: <span style={{ fontWeight: 'normal' }}>Sora mở ra khả năng sáng tạo vô hạn cho ngành giải trí, giúp tạo ra các video giải trí độc đáo, sáng tạo.</span></h4>
                                        </div>
                                        <div className="post-thumbnail mt--50">
                                            <video className="w-100" controls>
                                                <source src={require('../assets/images/blog/blog_2_2.mp4')} type="video/mp4"/>
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <div className="content mt--50">
                                            <h3>Những tác động của Sora:</h3>
                                            <h4 style={{ fontWeight: 'normal' }}>Sora được coi là “cú hích” cho ngành công nghiệp video, mang đến nhiều thay đổi và hứa hẹn bùng nổ trong tương lai.</h4>
                                            <h4 className="content ml--30">Dân chủ hóa việc sáng tạo video: <span style={{ fontWeight: 'normal' }}>Sora giúp mọi người dễ dàng tạo ra video mà không cần kỹ năng chuyên môn, thúc đẩy sự sáng tạo và đa dạng hóa nội dung video.</span></h4>
                                            <h4 className="content ml--30">Nâng cao hiệu quả sản xuất video: <span style={{ fontWeight: 'normal' }}>Sora giúp rút ngắn thời gian và chi phí sản xuất video, tăng năng suất cho ngành công nghiệp video.</span></h4>
                                            <h4 className="content ml--30">Mở ra thị trường mới: <span style={{ fontWeight: 'normal' }}>Sora tạo ra tiềm năng phát triển cho các dịch vụ liên quan như chỉnh sửa video, phân tích dữ liệu,...</span></h4>
                                        </div>
                                        <div className="post-thumbnail mt--50">
                                            <img className="w-100"
                                                 src={require('../assets/images/blog/blog_2_3.jpg')}
                                                 alt="Blog Images"/>
                                        </div>
                                        <div className="content mt--50">
                                            <h4 style={{ fontWeight: 'normal' }}>Sora là bước tiến đột phá trong công nghệ tạo video, mang đến vô vàn tiềm năng 
                                                cho ngành công nghiệp video. Với sự phát triển không ngừng, Sora hứa hẹn sẽ tiếp tục bứt phá, 
                                                tạo ra những thay đổi mang tính cách mạng trong tương lai.</h4>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Footer */}
                <Footer horizontal="horizontal"/>

            </div>
        )
    }
}

export default BlogDetails;














