import React, { Component } from "react";
import Popup from "./Popup";
class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDialog: false,
        };
    }
    openConfirmDialog = () => {
        this.setState({ showConfirmDialog: true });
    };

    closeConfirmDialog = () => {
        this.setState({ showConfirmDialog: false });
    };
    render() {
        return (
            <div className={`app-about ${this.props.horizontalabout}`} id="about">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>ABOUT <span className="theme-color">APNEW</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majorityhave suffered alteration in some form, by injected humour,available</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="row align-items-center about-section">
                        <div className="col-xl-5 mt--40 about-section-chilFirst" data-aos="fade-right">
                            {/* <div className="about-thumbnail mr--35"> */}
                            {/* <img className="image-1" src={require('../assets/images/app/mobile.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/mobile-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/mobile-3.png')} alt="App Landing"/> */}
                            <div style={{
                                position: 'sticky', top: '100px',
                                backgroundImage: `url(${require('../assets/video/mtpreview.png')})`,
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '100%',
                                height: '416px',
                                width: '475px'
                            }}>
                                <iframe className="about-section-chilFirst-iframe" style={{
                                    width: '94%',
                                    height: '59%',
                                    transform: 'translate(2px, 15px)'
                                }} width="560" height="315" src="https://www.youtube.com/embed/ROAqjFvRKE0?si=3tRQE0czvadv3aox" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="col-xl-6 offset-xl-1 mt--40 about-section-chilLast" data-aos-delay="500" data-aos="fade-left">
                            <div className="about-content">
                                {/* <h2 className="title">BEST <span className="theme-color">MOBILE APP</span></h2> */}
                                <h2 className="title">GIỚI THIỆU</h2>
                                <p>Vpress là thành quả hợp tác toàn diện giữa Giải pháp mới - đơn vị đi đầu trong việc cung cấp các giải pháp dịch vụ chuyên nghiệp, sáng tạo -  và GCT - công ty công nghệ với hơn 10 năm kinh nghiệm trong chuyển đổi số cho các doanh nghiệp.</p>
                                <p>Từ sự thấu hiểu với người làm báo và tận dụng tối đa những lợi thế của trí tuệ nhân tạo AI, Vpress sẽ giúp tối ưu hóa quy trình sản xuất nội dung và mang đến những trải nghiệm tuyệt vời nhất cho cả tòa soạn và bạn đọc. </p>
                                <div className="about-buttons">
                                    <button type="button" className="button-default button-olive" onClick={this.openConfirmDialog}>Liên hệ ngay</button>
                                    {/*<a className="button-default button-border" href="/" role="button">Learn more</a>*/}
                                </div>
                                <Popup
                                    isOpen={this.state.showConfirmDialog}
                                    onRequestClose={this.closeConfirmDialog}
                                    onConfirm={(inputValue) => {
                                        console.log('Thông tin được nhập:', inputValue);
                                        this.closeConfirmDialog();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;











