import React, { Component } from "react";


class BlogPost extends Component {
    render() {
        let data = [
            {
                image: "blog_1_2.png",
                title: 'Thấy được gì qua sự cố “sập” Facebook?',
                link: 'thay-duoc-gi-qua-su-co-sap-facebook',
                date: '14/3/2024',
                // postBy:'Nipa Bali',
                desc: 'Có phải rất nhiều người trong chúng ta đã quá lệ thuộc vào mạng xã hội và khi nó đột ngột "dừng", đã để lại một khoảng trống quá lớn?'
            },

            {
                image: "blog_2_3.jpg",
                title: 'SORA - “Cơn sốt” mới trong ngành công nghiệp video.',
                link: 'sora-con-sot-moi-trong-nganh-cong-nghiep-video',
                date: '14/3/2024',
                // postBy:'Fatima Asrafi',
                desc: 'Mới ra mắt chỉ một thời gian ngắn, công cụ tạo video mới của OpenAI đang gây chấn động ngành công nghiệp video trên toàn cầu.'
            },
            {
                image: "blog_3_1.gif",
                title: 'AI của Alibaba "hô biến" ảnh tĩnh thành biết nói và hát',
                link: 'ai-cua-alibaba-ho-bien-anh-tinh-thanh-biet-noi-va-bai-hat',
                date: '14/3/2024',
                // postBy:'John Snow',
                desc: 'EMO có khả năng biến ảnh tĩnh bất kỳ thành video ca nhạc sống động, hứa hẹn mang đến nhiều trải nghiệm giải trí mới mẻ và độc đáo.'
            },
            {
                image: "img1.jpg",
                title: '6 "mẹo" chụp ảnh chuyên nghiệp bằng điện thoại cho người làm báo',
                link: '6-tips-chup-anh-chuyen-nghiep-bang-dien-thoai-cho-nguoi-lam-bao',
                date: '14/3/2024',
                desc: 'Chụp ảnh là một kỹ năng quan trọng đối với người làm báo. Thế nhưng, không phải ai cũng biết cách "hô biến" những tấm ảnh đơn điệu trở nên chuyên nghiệp và có chiều sâu hơn.'
            },
            {
                image: "blog-6-1.jpg",
                title: 'Cơ hội kiếm tiền nhờ sự bùng nổ của AI',
                link: 'co-hoi-kiem-tien-nho-su-bung-no-cua-ai',
                date: '14/3/2024',
                desc: 'Trong thời đại công nghệ 4.0, AI không chỉ là “chìa khóa” mở ra tương lai mà còn là “cánh cửa vàng” cho những ai biết nắm bắt cơ hội. Tìm hiểu các cách thức kiếm tiền từ AI trong bài viết. '
            },
            {
                image: "blog_4.jpg",
                title: 'LHQ thông qua Nghị quyết đầu tiên về Trí tuệ nhân tạo (AI)',
                link: 'lhq-thong-qua-nghi-quyet-dau-tien-ve-tri-tue-nhan-tao-ai',
                date: '14/3/2024',
                desc: 'Việc Liên Hợp Quốc thông qua Nghị quyết đầu tiên về Trí tuệ nhân tạo, chúng ta đang chứng kiến bước ngoặt lịch sử mở ra kỷ nguyên mới cho AI.'
            }, {
                image: "blog71.jpg",
                title: 'ChatGPT gây tranh cãi khi được sử dụng trong điện ảnh ',
                link: 'chatGPT-gay-tranh-cai-khi-duoc-su-dung-trong-dien-anh',
                date: '15/4/2024',
                desc: 'Việc ứng dụng trí tuệ nhân tạo như ChatGPT trong ngành công nghiệp điện ảnh đã gây ra tranh cãi trong thời gian gần đây. '
            }
            ,
            {
                image: "blog8.jpg",
                title: 'Higgsfield: Bậc thầy chuyển động mới trong thế giới AI sáng tạo',
                link: 'higgsfield-bac-thay-chuyen-dong-moi-trong-the-gioi-AI-sang-tao',
                date: '15/4/2024',
                desc: 'Chất lượng video từ mô hình AI tạo sinh video mới của OpenAI được đánh giá ngang ngửa sản phẩm từ các đội ngũ làm phim chuyên nghiệp.'
            }
            ,
            {
                image: "blog91.png",
                title: 'Tương lai của ngành báo chí và trí tuệ nhân tạo',
                link: 'tuong-lai-cua-nganh-bao-chi-va-tri-tue-nhan-tao',
                date: '15/4/2024',
                desc: 'Trí tuệ nhân tạo đang được sử dụng trong hoạt động báo chí ở nhiều nước, trong đó có Việt Nam. '
            },
            {
                image: "blog10.png",
                title: 'Mối lo ngại của GPT-4 vì nguy cơ tấn công mạng',
                link: 'moi-lo-ngai-cua-gpt-4-vi-nguy-co-tan-cong-mang',
                date: '26/4/2024',
                desc: 'GPT-4, mô hình trí tuệ nhân tạo tiên tiến của OpenAI, đang thu hút sự quan tâm của cộng đồng bảo mật mạng do rủi ro về khai thác lỗ hổng an ninh mạng một cách tự động.'
            },
            {
                image: "blog11.jpg",
                title: 'Meta AI sử dụng trợ lý AI cho Facebook và Messenger',
                link: 'meta-ai-su-dung-tro-ly-ai-cho-facebook-va-messenger',
                date: '26/4/2024',
                desc: 'Meta đã chính thức ra mắt trợ lý AI mới có tên Meta AI, có sẵn trên Facebook, Instagram, Messenger và WhatsApp tại một số quốc gia, và dự kiến sẽ sớm được triển khai rộng rãi hơn trong tương lai.'
            }
            ,
            {
                image: "blog12.jpg",
                title: 'Biến ảnh tĩnh thành video AI với Microsoft VASA-1',
                link: 'bien-anh-tinh-thanh-video-ai-voi-microsoft-vasa-1',
                date: '26/4/2024',
                desc: 'Microsoft đã chính thức giới thiệu VASA-1, công cụ trí tuệ nhân tạo tiên tiến có khả năng chuyển đổi ảnh tĩnh thành video chân thực với chuyển động và giọng nói một cách tự nhiên, mang lại trải nghiệm mới mẻ.'
            },
            {
                image: "blog13.jpg",
                title: 'Đọc hiểu file FDF nhanh chóng bằng Adobe AI',
                link: 'doc-hieu-file-fdf-nhanh-chong-bang-adobe-ai',
                date: '26/4/2024',
                desc: 'Acrobat AI Assistant, công cụ mới của Adobe, người dùng chỉ cần chat để hiểu nội dung file PDF mà không cần đọc toàn bộ.'
            },
            {
                image: "blog15_1.png",
                title: 'Bí quyết viết chuẩn SEO dành cho dân báo chí',
                link: 'bi-quyet-viet-chuan-seo-danh-cho-dan-bao-chi',
                date: '23/5/2024',
                desc: 'Nâng tầm kỹ năng viết bài, thu hút traffic, bứt phá thứ hạng tìm kiếm với bí quyết viết chuẩn SEO dành riêng cho dân báo chí'
            },
            {
                image: "blog16_1.jpg",
                title: 'Google tăng cường cuộc đua vũ khí mới với AI',
                link: 'google-tang-cuong-cuoc-dua-vu-khi-moi-voi-ai',
                date: '23/5/2024',
                desc: 'Alphabet (Công ty mẹ của Google) ra mắt "vũ khí AI" mới tại sự kiện dành cho nhà phát triển I/O 2024: Nâng cấp chatbot, cải thiện tìm kiếm và cạnh tranh mạnh mẽ'
            }
            ,
            {
                image: "blog17_3.jpg",
                title: 'Có nên áp dụng Paywall tại Việt Nam hay không ? ',
                link: 'co-nen-ap-dung-paywall-tai-viet-nam-hay-khong',
                date: '23/5/2024',
                desc: 'Trong cơn sóng dữ dội của truyền thông toàn cầu, báo chí Việt Nam đang đứng trước ngã rẽ mang tính then chốt: Áp dụng hay từ chối mô hình Paywall - thu phí người đọc trực tuyến?'
            },
            {
                img: "https://cdnphoto.dantri.com.vn/te3G7gl08HHQm0pC0qKXvlcefOs=/thumb_w/1020/2024/07/19/lcimg-5416bf03-1f8b-4709-b65b-ab1615785cba-1721389229145.jpeg",
                title: 'Lỗi "Màn Hình Xanh" Trên Windows Gây Ảnh Hưởng Toàn Cầu',
                link: 'loi-man-hinh-xanh-tren-windows-gay-anh-huong-toan-cau',
                date: '23/7/2024',
                desc: 'Một sự cố công nghệ nghiêm trọng đã làm hàng ngàn chuyến bay bị hủy, hệ thống ngân hàng và bệnh viện ngừng hoạt động, ảnh hưởng đến hàng triệu người dùng trên toàn thế giới.'
            },
            {
                image: "img192.jpg",
                title: 'Blockchain: Bước tiến mới trong bảo mật báo chí',
                link: 'blockchain-buoc-tien-moi-trong-bao-mat-bao-chi',
                date: '23/7/2024',
                desc: 'Blockchain không chỉ được sử dụng trong Bitcoin, công nghệ này còn có nhiều tiềm năng ứng dụng trong lĩnh vực bảo mật báo chí'
            }, {
                image: "img201.jpg",
                title: 'Big Data: Chìa khóa để hiểu độc giả tốt hơn',
                link: 'big-data-chia-khoa-de-hieu-doc-gia-tot-hon',
                date: '23/7/2024',
                desc: 'Khám phá công cụ mà các tòa soạn báo sử dụng để phân tích hành vi, sở thích và tâm lý của độc giả, từ đó tối ưu hóa nội dung, tăng cường bảo mật và minh bạch.'
            },
        ];
        let DataList = data.reverse().map((val, i) => {
            return (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                    <div className="blog">
                        <div className="thumb">
                            <a href={val.link}><img className="w-100" src={val.img || require(`../assets/images/blog/${val.image}`)} alt="Blog images" /></a>
                        </div>
                        <div className="inner">
                            <h4 className="title"><a href={val.link}>{val.title}</a></h4>
                            <ul className="meta">
                                <li>{val.date}</li>
                                {/* <li>By <a href="/">{val.postBy}</a></li> */}
                            </ul>
                            <p className="desc mt--10 mb--30">{val.desc}</p>
                            <div className="blog-btn">
                                <a className="button-link" href={val.link}>Đọc thêm</a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
        return (
            <div className="blog-area pt--120">
                <div className="container">
                    <div className="row">
                        {DataList}
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="pagination-wrapper mt--45">
                                <ul className="page-list">
                                    <li className="active"><a href="/">1</a></li>
                                    <li><a href="/">2</a></li>
                                    <li><a href="/">3</a></li>
                                    <li><a href="/"><i className="zmdi zmdi-arrow-right"/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default BlogPost;
