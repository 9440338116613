import React, { Component } from "react";



class Feature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeId: 0,
            videoUrl: require('../assets/video/taptrung.mp4'),
            // imgUrl:require('../assets/images/app/feature.jpg')
        };
    }
    render() {
        let data = [
            {
                icon: 'zmdi zmdi-collection-text',
                title: 'Quản lý tập trung',
                desc: 'Vpress là nền tảng all-in-one (tất cả trong một) - mọi hoạt động của tòa soạn được tích hợp và quản lý trên 1 nền tảng duy nhất, bao gồm: Hệ thống CMS, quy trình nghiệp vụ báo chí, tổ chức tòa soạn và hệ thống dữ liệu.',
                videoUrl: require('../assets/video/taptrung.mp4')
            },

            {
                videoUrl: require('../assets/video/danentang.mp4'),
                icon: 'zmdi zmdi-sun',
                title: 'Đa nền tảng',
                desc: 'Cho phép xuất bản đồng thời 1 nguồn nội dung cho nhiều kênh khác nhau (báo in, báo điện tử, chuyên trang điện tử, mạng xã hội).'
            },

            {
                videoUrl: require('../assets/video/linhhoat.mp4'),
                icon: 'zmdi zmdi-brush',
                title: 'Linh hoạt',
                desc: 'Quy trình nghiệp vụ, quản lý tổ chức cùng nhiều tính năng khác đều có tính “động”, có thể tùy chỉnh theo nhu cầu và quy mô của từng tòa soạn.'
            },

            {
                videoUrl: require('../assets/video/ai.mp4'),
                icon: 'zmdi zmdi-desktop-mac',
                title: 'Tích hợp AI',
                desc: 'Tận dụng tối đa trí tuệ nhân tạo trong sản xuất nội dung và cá nhân hóa trải nghiệm người dùng như trợ lý ảo AI, AI tạo sinh chuyển văn bản thành hình ảnh, âm thanh,..'
            },

            {
                videoUrl: require('../assets/video/baoin.mp4'),
                icon: 'zmdi zmdi-collection-bookmark',
                title: 'Quản lý báo in hiệu quả',
                desc: 'Tự động dàn trang, xuất file PDF,... các bài viết báo in, cho phép linh hoạt chuyển đổi từ nội dung xuất bản báo điện tử sang báo in và ngược lại.'
            },

            {
                videoUrl: require('../assets/video/open.mp4'),
                icon: 'zmdi zmdi-wrench',
                title: 'Khả năng mở rộng cao',
                desc: 'Dễ dàng nâng cấp, phát triển các nghiệp vụ và mô hình tổ chức khi tòa soạn có nhu cầu.'
            }
        ]
        const handleClickActive = (id) => {
            if (this.state.activeId === id) return
            this.setState({ activeId: id });
        }
        let DataList = data.map((val, i) => {
            return (
                /* Start Single Feature */
                <div onClick={() => handleClickActive(i)} className={`feature ${this.state.activeId === i ? 'active-shadow' : ''}`} key={i}>
                    <div className="feature-icon">
                        <i className={`${val.icon}`} />
                    </div>
                    <div className="content">
                        <h4 className="title">{val.title}</h4>
                        <p className="desc">{val.desc}</p>
                    </div>
                </div>
                /* End Single Feature */
            )
        })

        return (
            <div>
                <div className={`feature-area feature-bg-image pb--50 ${this.props.horizontalfeature}`}
                    id="features">
                    <div className="container">
                        {/* <div className="row">  }} style={{ backgroundImage: `url('${this.state.imgUrl}')`}}
                       <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>AWESOME <span className="theme-color">FEATURES</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majorityhave suffered alteration in some form, by injected humour,available</p>
                            </div>
                       </div>
                   </div> */}{/*  */}
                        <div className="row mt--30" style={{ gap: '5rem', transition: '0.5s' }} >
                            <div className="col-xl-5 video-container">
                                <div style={{
                                    position: 'sticky', top: '100px',
                                    backgroundImage: `url(${require('../assets/video/mtpreview.png')})`,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '100%',
                                    height: '417px',
                                    width: '475px'
                                }}>
                                    {this.state.activeId === 0 && (
                                        <video style={{
                                            width: '94%',
                                            height: '61%',
                                            transform: 'translate(2px, 7px)'
                                        }}
                                            controls loop autoPlay>
                                            <source src={data[0].videoUrl} type="video/mp4" />
                                        </video>
                                    )}
                                    {this.state.activeId === 1 && (
                                        <video style={{
                                            width: '94%',
                                            height: '61%',
                                            transform: 'translate(2px, 7px)'
                                        }}
                                            controls loop autoPlay>
                                            <source src={data[1].videoUrl} type="video/mp4" />
                                        </video>
                                    )}
                                    {this.state.activeId === 2 && (
                                        <video style={{
                                            width: '94%',
                                            height: '61%',
                                            transform: 'translate(2px, 7px)'
                                        }}
                                            controls loop autoPlay>
                                            <source src={data[2].videoUrl} type="video/mp4" />
                                        </video>
                                    )}
                                    {this.state.activeId === 3 && (
                                        <video style={{
                                            width: '94%',
                                            height: '61%',
                                            transform: 'translate(2px, 7px)'
                                        }}
                                            controls loop autoPlay>
                                            <source src={data[3].videoUrl} type="video/mp4" />
                                        </video>
                                    )}
                                    {this.state.activeId === 4 && (
                                        <video style={{
                                            width: '94%',
                                            height: '61%',
                                            transform: 'translate(2px, 7px)'
                                        }}
                                            controls loop autoPlay>
                                            <source src={data[4].videoUrl} type="video/mp4" />
                                        </video>
                                    )}
                                    {this.state.activeId === 5 && (
                                        <video style={{
                                            width: '94%',
                                            height: '61%',
                                            transform: 'translate(2px, 7px)'
                                        }}
                                            controls loop autoPlay>
                                            <source src={data[5].videoUrl} type="video/mp4" />
                                        </video>
                                    )}
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="feature-list" data-aos="fade-left">
                                    {DataList}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Feature;
