import React, { Component } from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class BlogDetails extends Component {
    render() {
        return (
            <div className="blog-site">
                {/* Header Navbar */}
                <Navbar />

                {/* breadcrumb Area */}
                <div className="breadcrumb-area ptb--120 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h2 className="title">Meta AI sử dụng trợ lý AI cho Facebook và Messenger</h2>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Trang chủ</a></li>
                                        <li>-</li>
                                        <li>Chi tiết bài viết</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Blog Details */}
                <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-inner">
                                    <article className="single-post-details">
                                        <div className="content mt--50">
                                            <h2>Meta AI sử dụng trợ lý AI cho Facebook và Messenger</h2>
                                            <h4 style={{ fontWeight: 'normal' }}>Meta đã chính thức ra mắt trợ lý AI mới có tên Meta AI, có sẵn trên Facebook, Instagram, Messenger và WhatsApp tại một số quốc gia, và dự kiến sẽ sớm được triển khai rộng rãi hơn trong tương lai.</h4>
                                        </div>
                                        <div className="content mt--50">
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Theo thông tin mới nhất, Meta AI sẽ trở thành trợ lý trí tuệ nhân tạo (AI) được trang bị mô hình LLaMA 3, được tích hợp vào nền tảng WhatsApp, Instagram, Facebook và Messenger.</h4>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Theo thông báo của Meta vào ngày 18/4, Meta AI hiện đã được cung cấp miễn phí trên các sản phẩm chính của công ty và hoạt động độc lập trên trang web riêng là meta.ai . Tuy nhiên, việc triển khai mới chỉ diễn ra ở khoảng 10 quốc gia ở châu Âu và Mỹ, chưa có tại Việt Nam.</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog11.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Trên một bài đăng trên Instagram, CEO Mark Zuckerberg cho biết Meta AI dễ sử dụng hơn các sản phẩm cạnh tranh nhờ tích hợp sẵn vào công cụ tìm kiếm trong loạt ứng dụng có hàng triệu đến hàng tỷ người dùng.</h4>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Một trong những tính năng mà Zuckerberg đề cập là khả năng tạo ảnh từ văn bản theo thời gian thực. Ông nói rằng "Meta AI tích hợp LLaMA 3 có thể tạo ra ảnh chất lượng cao nhanh chóng, với thay đổi hiển thị theo từ khóa mà người dùng nhập vào. AI cũng sẽ tạo một video ghi lại quá trình sáng tạo đó".</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog111.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>LLaMA 3 là một mô hình ngôn ngữ lớn (LLM) vừa được Meta giới thiệu với hai phiên bản đào tạo có 8 tỷ và 70 tỷ tham số. Công ty mô tả rằng LLM này giảm đáng kể tỷ lệ phản hồi sai, cải thiện khả năng liên kết và tăng tính đa dạng trong phản hồi.
                                                </h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog112.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>"Với LLaMA 3, chúng tôi đã đặt mục tiêu xây dựng mô hình nguồn mở tốt nhất hiện nay, ngang hàng với các mô hình độc quyền mạnh nhất", Meta cho biết. "LLaMA 3 sẽ là một LLM đa ngôn ngữ và đa phương thức, xử lý ngữ cảnh dài hơn và tiếp tục cải thiện hiệu suất tổng thể".
                                                </h4>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Cả hai phiên bản LLaMA 3 đều được tích hợp trong Meta AI. Theo The Verge, công ty đang nghiên cứu một phiên bản LLaMA 3 khác với 400 tỷ tham số. Với mô hình mới này, Zuckerberg  tin rằng Meta AI hiện là trợ lý trí tuệ nhân tạo thông minh nhất mà mọi người có thể sử dụng một cách thoải mái.
                                                </h4>
                                            </div>
                                            <div>
                                                <h4 style={{ fontWeight: 'normal' }}>LLaMA là một mô hình trí tuệ nhân tạo được Meta giới thiệu từ tháng 2/2023 khi sự phát triển của trí tuệ nhân tạo đạt đến đỉnh cao và hiện đang ở thế hệ thứ ba. Trong đó, LLaMA 3 là một phiên bản mã nguồn mở hoàn toàn, trong khi LLaMA 2 yêu cầu các công ty có hơn 700 triệu người dùng hoạt động hàng tháng phải xin giấy phép sử dụng.
                                                </h4>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Footer */}
                <Footer horizontal="horizontal" />

            </div>
        )
    }
}

export default BlogDetails;
