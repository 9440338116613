import React, { Component } from "react";
import Popup from "./Popup";

class HeroOlive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDialog: false,
        };
    }
    openConfirmDialog = () => {
        this.setState({ showConfirmDialog: true });
        document.documentElement.classList.toggle('overflow-hidden')
    };

    closeConfirmDialog = () => {
        this.setState({ showConfirmDialog: false });
        document.documentElement.classList.toggle('overflow-hidden')
    };
    render() {
        return (
            <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape}`} id="home" >
                <div className="container h-100">
                    <div className="row">
                        <div className="col-lg-7 h-100">
                            <div className="banner-text">
                                <div className="banner-table-cell">
                                    {/* <h1>AWESOME <br /> 
                                    <span className="theme-color">apnew</span> 
                                    MOBILE APP.</h1> */}
                                    <h1>VPRESS - NỀN TẢNG TÒA SOẠN HỘI TỤ</h1>
                                    <h4>Với công nghệ đột phá và giải pháp sáng tạo, Vpress sẽ là đối tác tin cậy của các  tòa soạn báo, cùng nhau kiến tạo nên những giá trị bền vững trong kỷ nguyên chuyển đổi số báo chí.</h4>
                                    <br></br>
                                    <div className="banner-buttons">
                                        <button type="button" className="button-default button-olive" onClick={this.openConfirmDialog} s>Liên hệ ngay</button>
                                        {/*<a className="button-default button-white" href="/" role="button">Learn more</a>*/}
                                    </div>
                                    <Popup
                                        isOpen={this.state.showConfirmDialog}
                                        onRequestClose={this.closeConfirmDialog}
                                        onConfirm={(inputValue) => {
                                            console.log('Thông tin được nhập:', inputValue);
                                            this.closeConfirmDialog();
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-lg-5 mt--40">

                            </div> */}

                            <div className="banner-apps">
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <a href="https://www.facebook.com/vpress.vn">
                                            <i class="bi bi-facebook"></i>
                                            {/* <h4>Facebook</h4> */}
                                            {/* <h3>102K</h3> */}
                                        </a>
                                    </div>
                                </div>

                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <a href="https://www.tiktok.com/@giaiphapmoipro">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" class="bi bi-tiktok" viewBox="0 0 16 16">
                                                <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                                            </svg>
                                            {/* <h4>TikTok</h4> */}
                                            {/* <h3>102K</h3> */}
                                        </a>
                                    </div>
                                </div>

                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <a href="https://www.youtube.com/channel/UCgCMj4ieOgabpiQzqfcOi0w">
                                            <i className="bi bi-youtube" />
                                            {/* <h4>Youtube</h4> */}
                                            {/* <h3>102K</h3> */}
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-5">
                            <div className="banner-product-image text-right">
                                {/* <img className="image-1" src={require('../assets/images/app/banner-mobile.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/banner-mobile-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/banner-mobile-3.png')} alt="App Landing"/> */}
                                <img src={require('../assets/images/app/giai-phap-pr-bao-chi-adsmo.png')} alt="App Landing" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeroOlive;








