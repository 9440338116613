import React, { Component } from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class BlogDetails extends Component {
    render() {
        return (
            <div className="blog-site">
                {/* Header Navbar */}
                <Navbar />

                {/* breadcrumb Area */}
                <div className="breadcrumb-area ptb--120 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h2 className="title">Đọc hiểu file FDF nhanh chóng bằng Adobe AI</h2>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Trang chủ</a></li>
                                        <li>-</li>
                                        <li>Chi tiết bài viết</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Blog Details */}
                <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-inner">
                                    <article className="single-post-details">
                                        <div className="content mt--50">
                                            <h2>Đọc hiểu file FDF nhanh chóng bằng Adobe AI</h2>
                                            <h4 style={{ fontWeight: 'normal' }}>Acrobat AI Assistant, công cụ mới của Adobe, người dùng chỉ cần chat để hiểu nội dung file PDF mà không cần đọc toàn bộ.</h4>
                                        </div>
                                        <div className="content mt--50">
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Acrobat AI Assistant là một công cụ mới của Adobe giúp người dùng tương tác với file PDF mà không cần đọc toàn bộ nội dung. Giám đốc điều hành Adobe, Shantanu Narayen cho biết công cụ này có khả năng phân tích các trang tài liệu PDF phức tạp và đưa ra câu trả lời thông qua các truy vấn, đặc biệt hữu ích trong việc học tập và xem tài liệu thuế. Acrobat AI Assistant hoạt động trên nền tảng phần mềm Acrobat và hỗ trợ đa nền tảng, bao gồm phiên bản cho máy tính và tiện ích mở rộng trên trình duyệt. Phiên bản di động cũng có tính năng truy vấn bằng giọng nói, nhưng hiện đang trong giai đoạn thử nghiệm.</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog131.jpeg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Tuy nhiên, Acrobat AI Assistant không có sẵn trong phiên bản miễn phí Adobe Reader, mà chỉ có trong Acrobat Pro. Người dùng phải trả 4,99 USD để có "truy cập sớm". Theo Fortune, điều này có nghĩa là giá phần mềm có thể tăng trong tương lai.</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog133.png')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Acrobat được phát hành lần đầu vào năm 1993 và chủ yếu được sử dụng để đọc file PDF. Nó là một phần quan trọng trong dịch vụ đám mây của Adobe và mang lại doanh thu tỷ đô cho công ty. Theo báo cáo tài chính gửi cho Ủy ban Chứng khoán và Giao dịch Mỹ vào đầu năm, mảng Acrobat đã đóng góp 2,6 tỷ USD doanh thu cho Adobe trong năm 2023.</h4>
                                                <video controls style={{width:'100%'}}>
                                                    <source src={require('../assets/images/blog/video13.mp4')} type="video/mp4" />
                                                </video>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Năm ngoái, Adobe đã bổ sung các tính năng trợ lý trí tuệ nhân tạo (AI) như Firefly và Generative Fill vào phần mềm chỉnh sửa ảnh nổi tiếng Photoshop. Những tính năng này giúp người dùng có thể thêm, mở rộng hoặc xóa các chi tiết trong ảnh chỉ bằng cách gõ văn bản, tương tự như Dall-E, Midjourney và các công cụ AI khác để vẽ tranh.</h4>
                                            </div>
                                            <div>
                                                <h4 style={{ fontWeight: 'normal' }}>Theo các chuyên gia, từ sau cơn sốt ChatGPT năm ngoái, ngày càng nhiều công ty công nghệ kết hợp trợ lý AI vào sản phẩm của mình. Yusuf Khan, người đứng đầu bộ phận khoa học dữ liệu và AI của Constellation, cho biết chúng ta đang dần chuyển từ cách tiếp cận dựa trên tìm kiếm sang cách tiếp cận dựa trên trò chuyện.
                                                </h4>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Footer */}
                <Footer horizontal="horizontal" />

            </div>
        )
    }
}

export default BlogDetails;
