import React, { Component } from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class BlogDetails extends Component {
    render() {
        return (
            <div className="blog-site">
                {/* Header Navbar */}
                <Navbar />

                {/* breadcrumb Area */}
                <div className="breadcrumb-area ptb--120 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h2 className="title">Google tăng cường cuộc đua vũ khí mới với AI</h2>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Trang chủ</a></li>
                                        <li>-</li>
                                        <li>Chi tiết bài viết</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Blog Details */}
                <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-inner">
                                    <article className="single-post-details">
                                        <div className="content mt--50">
                                            <h2>Google tăng cường cuộc đua vũ khí mới với AI</h2>
                                            <h4 style={{ fontWeight: 'normal' }}>Alphabet (Công ty mẹ của Google) ra mắt "vũ khí AI" mới tại sự kiện dành cho nhà phát triển I/O 2024: Nâng cấp chatbot, cải thiện tìm kiếm và cạnh tranh mạnh mẽ</h4>
                                        </div>
                                        <div className="content mt--50">
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Trong bối cảnh OpenAI, được Microsoft hậu thuẫn, ra mắt ChatGPT vào tháng 11/2022 và gây ấn tượng mạnh mẽ với công chúng, Google đã không ngừng nỗ lực làm mới các sản phẩm của mình. Các thông báo mới nhất tại sự kiện I/O thường niên của Google đã làm rõ sự cạnh tranh khốc liệt với OpenAI và khẳng định vị thế của Google trong lĩnh vực tìm kiếm trực tuyến và trí tuệ nhân tạo (AI).
                                                    Mới đây, Google đã ra mắt hai phiên bản Gemini 1.5 Flash  và Gemini 1.5 Pro với mức giá khác nhau.
                                                    <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog16_1.jpg')} />

                                                </h4>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Google cho biết,  Gemini 1.5 Flash có thể nhanh chóng tóm tắt các cuộc hội thoại, chú thích hình ảnh và video cũng như trích xuất dữ liệu từ các tài liệu và bảng dữ liệu lớn.
                                                    Phiên bản Gemini 1.5 Pro mới có thể hiểu được khối lượng tài liệu khoảng 1.500 trang hoặc tóm tắt 100 email. Công cụ này cũng có thể xử lý một giờ nội dung video hoặc 30.000 dòng code.
                                                </h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog16_2.jpg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Tại sự kiện I/O thường niên, Google cũng đã trình diễn thêm nhiều công nghệ mới, trong đó nổi bật là Project Astra và Veo, minh chứng cho sự tiến bộ vượt bậc của hãng trong lĩnh vực AI.
                                                </h4>
                                                {/* <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog15_2.png')} /> */}
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Trong một video giới thiệu tại sự kiện, người dùng đã triển khai Project Astra để xác định vị trí của một cái loa và định vị lại chiếc kính trong phòng. Google cũng tiết lộ khả năng kết hợp Project Astra với Gemini Live, trợ lý giọng nói và văn bản có âm thanh tự nhiên hơn so với Google Assistant trước đây.</h4>
                                                <video controls style={{ width: '100%' }}>
                                                    <source src={require('../assets/images/blog/blog16_4.mp4')} type="video/mp4" />
                                                </video>
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Google cũng công bố Veo, một mô hình AI có khả năng tạo ra video độ phân giải 1080p kéo dài hơn 1 phút. Tuy nhiên, Veo hiện chỉ dành cho một số nhà sáng tạo được chọn. Trong số đó có nhà làm phim nổi tiếng Donald Glover, chủ nhân của nhiều giải thưởng danh giá như 12 giải Grammy, 2 giải Quả cầu vàng và 2 giải Primetime Emmy. Việc hợp tác với Donald Glover cho thấy Google đang đặt kỳ vọng lớn vào tiềm năng của Veo trong lĩnh vực sáng tạo nội dung video.</h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog16_3.jpeg')} />
                                            </div>
                                            <div style={{ marginBottom: '15px' }}>
                                                <h4 style={{ fontWeight: 'normal' }}>Trước Google một ngày, OpenAI cũng đã cho ra mắt mô hình AI mới và phiên bản ChatGPT dành cho máy tính để bàn, cùng giao diện mới. Công ty cho biết mô hình mới với tên gọi GPT-4o, nhanh gấp đôi GPT-4 Turbo và giá thành chỉ bằng một nửa. Công cụ mới của ChatGPT cũng có khả năng xử lý 50 ngôn ngữ khác nhau. </h4>
                                                <img className="my-4 w-100  object-fit-cover " style={{ height: '576px' }} src={require('../assets/images/blog/blog16_5.jpg')} />
                                            </div>
                                            <div>
                                                <h4 style={{ fontWeight: 'normal' }}>Cuộc đua giữa Google và OpenAI không chỉ đơn thuần là cuộc chiến công nghệ mà còn là cuộc chiến về tầm nhìn và khả năng tạo ra những trải nghiệm AI tốt nhất cho người dùng. Với những cập nhật mới nhất, Google đang chứng minh rằng họ không chỉ theo kịp mà còn sẵn sàng vượt lên trong cuộc đua này.</h4>

                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Footer */}
                <Footer horizontal="horizontal" />

            </div>
        )
    }
}

export default BlogDetails;
