import React, { useState } from "react";
import emailjs from 'emailjs-com';

const Footer = (props) => {
    const [inputNameFooter, setInputNameFooter] = useState('');
    const [inputMailFooter, setInputMailFooter] = useState('');
    const [inputPhoneFooter, setInputPhoneFooter] = useState('');
    const [inputMessageFooter, setInputMessageFooter] = useState('');

    const sendEmailFooter = (e) => {
        e.preventDefault();
        const templateFooterParams = {
            fullname: inputNameFooter,
            email: inputMailFooter,
            phone: inputPhoneFooter,
            message: inputMessageFooter
        };

        emailjs.sendForm('service_7ua2hlj', 'template_ttobyzt', '#contact-form', 'sQ--p43yq-CtLRjIg', templateFooterParams)
            .then((result) => {
                console.log(result.text);
                alert("Gửi thông tin liên hệ thành công");
            }, (error) => {
                console.log(error.text);
                alert("Lỗi trong quá trình xử lý");
            });
        setInputNameFooter('');
        setInputMailFooter('');
        setInputMessageFooter('');
        setInputPhoneFooter('');
    };

    return (
        <div>
            <div className={`footer-area ${props.horizontal}`} id="support">
                <div className="footer-bg" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2 className="text-white">LIÊN HỆ</h2>
                                {/* <h2 className="text-white">GET <span className="theme-color">IN TOUCH</span></h2>
                                    <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                    <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                    <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/> */}
                                <p className="text-white">Liên hệ với Vpress ngay hôm nay để được tư vấn miễn phí!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-xl-5 offset-xl-1">
                            <div className="contact-inner">
                                <h3 className="title">Liên hệ ngay!</h3>
                                <form className="contact-form" id="contact-formFooter" onSubmit={sendEmailFooter}>
                                    <div className="input-box">
                                        <input type="text" placeholder="Họ và tên" name="fullname" value={inputNameFooter} onChange={(e) => setInputNameFooter(e.target.value)} required />
                                    </div>

                                    <div className="input-box">
                                        <input type="email" placeholder="Email" name="email" value={inputMailFooter} onChange={(e) => setInputMailFooter(e.target.value)} required />
                                    </div>

                                    <div className="input-box">
                                        <input type="tel" placeholder="Số điện thoại" name="phone" value={inputPhoneFooter} onChange={(e) => setInputPhoneFooter(e.target.value)} required />
                                    </div>

                                    <div className="input-box">
                                        <textarea placeholder="Nội dung" name="message" value={inputMessageFooter} onChange={(e) => setInputMessageFooter(e.target.value)} required></textarea>
                                    </div>

                                    <div className="input-box">
                                        <button className="submite-button" type="submit" style={{ fontWeight: 'bold' }}>Gửi</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-2 col-xl-4 offset-xl-2 mt_md--40 mt_sm--40">
                            <div className="contact-inner">
                                <h3 className="title">THÔNG TIN LIÊN HỆ</h3>
                                <div className="conatct-info">
                                    <div className="single-contact-info">
                                        <div className="contact-icon">
                                            <i className="zmdi zmdi-phone"></i>
                                        </div>
                                        <div className="contact-text">
                                            <a style={{ color: '#fff' }} href="tel:+02439999188">02439999188</a>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="contact-icon">
                                            <i className="zmdi zmdi-globe-alt"></i>
                                        </div>
                                        <div className="contact-text" >
                                            <span>contact@gct.com.vn</span>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="contact-icon">
                                            <i className="zmdi zmdi-pin"></i>
                                        </div>
                                        <div className="contact-text">
                                            <span>Số 93A Thụy Khuê, phường Thụy Khuê, <br />quận Tây Hồ, thành phố Hà Nội, Việt Nam</span>
                                        </div>
                                    </div>
                                    <div className="footer-links text-center">
                                        <a href="https://www.facebook.com/vpress.vn"><i className="zmdi zmdi-facebook"></i></a>
                                        {/* <a href="https://www.tiktok.com/@giaiphapmoipro"><i className="zmdi zmdi-tiktok"></i></a> */}
                                        <a href="https://www.tiktok.com/@giaiphapmoipro">
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-tiktok" viewBox="0 0 16 20">
                                                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                                                </svg>
                                            </i>
                                        </a>
                                        <a href="https://www.youtube.com/channel/UCgCMj4ieOgabpiQzqfcOi0w"><i className="zmdi zmdi-youtube-play"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row footer-end">
                        <div class="image-container">
                            <a href="https://giaiphapmoipro.vn/ "><img src={require('../assets/images/logo/nsg.png')} alt="Logo New Solution" style={{ width: '150px', height: '50px' }} /></a>
                            <a href="https://gct.com.vn/ "><img src={require('../assets/images/logo/GCT.png')} alt="Logo GCT" style={{ width: '100px', height: '45px' }} /></a>
                        </div>
                        <div className="newsletter text-center">
                            <h5 className="text-white title">Vpress - Đồng hành cùng các tòa soạn trên hành trình chuyển đổi số</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-xl-4 offset-xl-4">

                            <div className="footer-text text-center">
                                <span>Copyright © {new Date().getFullYear()} <a href="https://www.facebook.com/vpress.vn">VPress</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tap-top">
                <div>
                    <i className="zmdi zmdi-long-arrow-up"></i>
                </div>
            </div>
        </div>
    )
}
// }
export default Footer







