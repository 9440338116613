import React, {Component} from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class BlogDetails extends Component {
    render() {
        return (
            <div className="blog-site">
                {/* Header Navbar */}
                <Navbar/>

                {/* breadcrumb Area */}
                <div className="breadcrumb-area ptb--120 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h2 className="title">LHQ thông qua Nghị quyết đầu tiên về Trí tuệ nhân tạo (AI)</h2>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Trang chủ</a></li>
                                        <li>-</li>
                                        <li>Chi tiết bài viết</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Blog Details */}
                <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-inner">
                                    <article className="single-post-details">
                                        <div className="content mt--50">
                                            <h2>LHQ thông qua Nghị quyết đầu tiên về Trí tuệ nhân tạo (AI)</h2>
                                            <h4 style={{ fontWeight: 'normal' }}>Ngày 21/3 (giờ New York), Đại hội đồng Liên Hợp Quốc đã phê duyệt nghị quyết quan trọng nhằm thúc đẩy việc phát triển trí tuệ nhân tạo an toàn, bảo mật và đáng tin cậy, góp phần vào sự phát triển 
                                            bền vững toàn cầu. Nghị quyết này được Mỹ đề xuất và nhận được sự đồng thuận của hơn 120 quốc gia, trong đó có Việt Nam.</h4>
                                        </div>
                                        <div className="content mt--50">
                                            <h4 style={{ fontWeight: 'normal' }}>Từ ảnh tĩnh thành video: Nghị quyết này được coi là văn kiện chính thức đầu tiên của Đại hội đồng Liên Hợp Quốc về trí tuệ nhân tạo (AI) khẳng định vai trò của 
                                            các hệ thống AI an toàn, đảm bảo và tin cậy trong việc thúc đẩy phát triển bền vững và đạt được 17 Mục tiêu phát triển bền vững 
                                            (SDGs). Ngoài ra, nghị quyết cũng nhấn mạnh mặt trái của AI nếu không có cơ chế quản trị thích hợp, như nguy cơ bị lạm dụng vào mục đích xấu, cản trở phát triển bền vững trên các mặt kinh tế, xã hội và môi trường, gây gia tăng bất bình đẳng.</h4>
                                        </div>
                                        <div className="content mt--50">
                                            <h4 style={{ fontWeight: 'normal' }}>Nghị quyết kêu gọi các quốc gia phối hợp xây dựng cơ chế quản trị AI ở cả cấp quốc gia và toàn cầu, vừa thúc đẩy nghiên cứu và ứng dụng AI vì phát triển bền vững, vừa kiểm soát hiệu quả các rủi ro đồng thời tăng 
                                            cường hỗ trợ các quốc gia đang phát triển trong việc tiếp cận công nghệ và nâng cao năng lực số.</h4>
                                        </div>
                                        <br></br>
                                        <div className="post-thumbnail">
                                            <img className="w-100"
                                                 src={require('../assets/images/blog/blog_4.jpg')}
                                                 alt="Blog Images"/>
                                                <p style={{textAlign: 'center'}}><em>Liên Hợp Quốc thông qua Nghi quyết đầu tiên về Trí tuệ nhân tạo_ Ảnh: Tư liệu</em></p>
                                        </div>
                                        <div className="content mt--50">
                                            <h4 style={{ fontWeight: 'normal' }}>Nghị quyết đầu tiên về AI này có những tác động gì đến các quốc gia và Việt Nam? Dưới đây là một số góc nhìn và dự đoán:</h4>
                                        </div>
                                        <div className="content mt--50">
                                            <h3>Tiềm năng ứng dụng rộng rãi:</h3>
                                            <h4 className="content ml--30">1. Tăng Cường Hợp Tác Quốc Tế: <span style={{ fontWeight: 'normal' }}>Nghị quyết khuyến khích việc thiết lập các chuẩn mực và khung 
                                            pháp lý quốc tế mới cho việc phát triển và sử dụng AI. Điều này giúp đảm bảo một sân chơi công bằng và cởi mở, nơi các quốc gia cùng nhau tham gia vào cuộc đua công nghệ 
                                            mà không làm tổn hại đến quyền lợi của nhau.</span></h4>
                                            <h4 className="content ml--30">2. Thúc Đẩy Phát Triển Bền Vững: <span style={{ fontWeight: 'normal' }}>Việc nhấn mạnh vai trò của AI trong việc đạt được Mục tiêu Phát
                                             triển Bền vững (SDGs) của Liên Hợp Quốc có thể thúc đẩy việc sử dụng AI để giải quyết các thách thức toàn cầu như nghèo đói, đói ăn, biến đổi khí hậu, và bất bình 
                                             đẳng giới. Các ứng dụng AI có thể được tối ưu hóa để phục vụ lợi ích của nhân loại trên toàn thế giới.</span></h4>
                                            <h4 className="content ml--30">3. Tăng cường Bảo vệ dữ liệu và Quyền riêng tư: <span style={{ fontWeight: 'normal' }}>Việc nhấn mạnh tầm quan trọng của việc tôn trọng 
                                            nhân quyền trong quá trình thiết kế và sử dụng AI có thể thúc đẩy việc phát triển các giải pháp công nghệ mới nhằm bảo vệ dữ liệu cá nhân và quyền riêng tư của người dùng.</span></h4>
                                            <h4 className="content ml--30">4. Thu hẹp khoảng cách kỹ thuật số: <span style={{ fontWeight: 'normal' }}>Đối với các quốc gia đang phát triển giảm bớt khoảng cách kỹ thuật số và
                                             thúc đẩy sự phát triển kỹ thuật số một cách công bằng và bền vững trên toàn cầu. Điều này cung cấp cho các quốc gia này cơ hội tốt hơn trong việc tận dụng lợi ích của
                                              AI cho sự phát triển kinh tế - xã hội.</span></h4>
                                              <h4 className="content ml--30">5. Ngăn chặn các rủi ro về mặt đạo đức: <span style={{ fontWeight: 'normal' }}>Nghị quyết giúp tăng cường nghiên cứu và phát
                                               triển về cách quản lý rủi ro và thách thức đạo đức liên quan đến AI, bao gồm cả việc sử dụng không đúng cách hoặc việc tạo ra các hệ thống AI với khả năng gây hại.
                                                Điều này bảo đảm AI phát triển theo hướng có ích cho xã hội và không gây ra hậu quả tiêu cực không mong muốn.</span></h4>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Footer */}
                <Footer horizontal="horizontal"/>

            </div>
        )
    }
}

export default BlogDetails;














